'use strict';

const BackToTop = {

    init() {
        const btnScrollTop = document.querySelector('#js-scroll-top');
        if (btnScrollTop) {
            let debounceTimer;
            window.addEventListener('scroll', function() {
                if (debounceTimer) {
                    clearTimeout(debounceTimer);
                }
                debounceTimer = setTimeout(function() {
                     // Calcul de la hauteur de défilement maximale
                    const maxScrollHeight = document.documentElement.scrollHeight - window.innerHeight;
                    // Calcul du pourcentage de défilement
                    const scrollPercentage = (window.scrollY / maxScrollHeight) * 100;
                    if (scrollPercentage > 10) {
                        btnScrollTop.classList.add('visible');
                    } else {
                        btnScrollTop.classList.remove('visible');
                    }
                }, 100);
            });
            btnScrollTop.addEventListener('click', () => {
                window.scrollTo({ top: 0, behavior: 'smooth' });
                this.moveToPageStart();
            });
        }
    },
    moveToPageStart() {
        const pageStart = document.querySelector('.c-header__logos'); 
        if (pageStart) {
            pageStart.setAttribute('tabindex', '-1');
            pageStart.focus();
            pageStart.removeAttribute('tabindex');
        }
    }
};

export default BackToTop;