'use strict';

const BurgerMenu = {

    init() {
        const header = document.querySelector('.c-header');
        if (header) {
            const burgerBtn = header.querySelector('.js-burger-btn');
            const dropdownHeader = header.querySelector('.js-btn-list');
            const btnCloseSubMenu = header.querySelector('.js-close-submenu');
            const btnCloseMenu = header.querySelector('.js-close-menu');
            this.itemHasChildrenAccessibility(header)
            if (burgerBtn) {
                burgerBtn.addEventListener('click', () => {
                    header.classList.toggle('open');
                    burgerBtn.setAttribute('aria-expanded', header.classList.contains('open'));
                    if (header.classList.contains('submenu-open')) {
                        header.classList.remove('submenu-open')
                    }
                })
                dropdownHeader?.addEventListener('click', () => {
                    if (window.innerWidth < 991) {
                        header.classList.toggle('submenu-open')
                    }
                })
                btnCloseSubMenu?.addEventListener('click', () => {
                    header.classList.remove('submenu-open')
                })
                btnCloseMenu?.addEventListener('click', () => {
                    header.classList.remove('submenu-open')
                    header.classList.remove('open')
                })
            }
        }
    },
    itemHasChildrenAccessibility(header) {
        const menuItems = header.querySelectorAll('.menu-item-has-children');

        if (menuItems.length > 0) {
            menuItems.forEach(item => {

                if (window.innerWidth < 991) {
                    item.querySelector('a').setAttribute('href', '#');
                }
                const toggleAriaExpanded = (value, event) => {
                    const anchor = item.querySelector('a');

                    if (anchor.getAttribute('aria-expanded') !== value) {
                        if (event.relatedTarget === null || !item.contains(event.relatedTarget)) {
                            anchor.setAttribute('aria-expanded', value);
                        }
                    }
                };

                const anchor = item.querySelector('a');
                anchor.setAttribute('aria-expanded', 'false');

                item.addEventListener('focusin', (event) => toggleAriaExpanded('true', event));
                item.addEventListener('focusout', (event) => toggleAriaExpanded('false', event));
                item.addEventListener('mouseenter', (event) => toggleAriaExpanded('true', event));
                item.addEventListener('mouseleave', (event) => toggleAriaExpanded('false', event));
            });
        }
    },
};

export default BurgerMenu;